import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import Logos from '../components/Logos'
import CommunityDemo from '../components/CommunityDemo'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  return (
    <div>
      <section className="pt-6 pt-md-11 pb-11 pb-md-13 bg-dark">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6 text-center text-white">
              <h6 className="text-uppercase text-success mb-5">
                welcome to reno collective
              </h6>
              <h2 className="display-3 text-center mb-6">
                The community for{' '}
                <span className="text-underline-warning d-xl-inline">
                  <span
                    data-typed=""
                    data-options='{"strings": ["WFH", "WFH.", "freelancers.", "remote work.", "startups.","upstarts.","you."]}'
                  >
                    remote work.
                  </span>
                </span>
              </h2>
              <Link
                to="/apply"
                className="btn btn-success"
                title="apply for membership"
              >Apply for Membership</Link>
              <p class="p-1">
                <small>
                  or{' '}
                  <Link
                    to="/join"
                    class="text-warning"
                    title="Get started right away here"
                  >
                    check out our memberships
                  </Link>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape shape-fluid-x shape-bottom text-dark pb-9 pb-md-15">
          <div className="shape-img pb-8 pb-md-11">
            <svg viewBox="0 0 100 50" preserveAspectRatio="none">
              <path d="M0 0h100v25H75L25 50H0z" fill="currentColor" />
            </svg>
          </div>
        </div>
      </div>
      <section>
        <div className="container-lg">
          <div className="row align-items-center mb-9 mb-md-10">
            <div className="col-12">
              <div className="position-absolute top-right text-primary-light mt-n10 mr-n8">
                <svg
                  width="185"
                  height="186"
                  viewBox="0 0 185 186"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="currentColor" />
                  <circle cx="22" cy="2" r="2" fill="currentColor" />
                  <circle cx="42" cy="2" r="2" fill="currentColor" />
                  <circle cx="62" cy="2" r="2" fill="currentColor" />
                  <circle cx="82" cy="2" r="2" fill="currentColor" />
                  <circle cx="102" cy="2" r="2" fill="currentColor" />
                  <circle cx="122" cy="2" r="2" fill="currentColor" />
                  <circle cx="142" cy="2" r="2" fill="currentColor" />
                  <circle cx="162" cy="2" r="2" fill="currentColor" />
                  <circle cx="182" cy="2" r="2" fill="currentColor" />
                  <circle cx="2" cy="22" r="2" fill="currentColor" />
                  <circle cx="22" cy="22" r="2" fill="currentColor" />
                  <circle cx="42" cy="22" r="2" fill="currentColor" />
                  <circle cx="62" cy="22" r="2" fill="currentColor" />
                  <circle cx="82" cy="22" r="2" fill="currentColor" />
                  <circle cx="102" cy="22" r="2" fill="currentColor" />
                  <circle cx="122" cy="22" r="2" fill="currentColor" />
                  <circle cx="142" cy="22" r="2" fill="currentColor" />
                  <circle cx="162" cy="22" r="2" fill="currentColor" />
                  <circle cx="182" cy="22" r="2" fill="currentColor" />
                  <circle cx="2" cy="42" r="2" fill="currentColor" />
                  <circle cx="22" cy="42" r="2" fill="currentColor" />
                  <circle cx="42" cy="42" r="2" fill="currentColor" />
                  <circle cx="62" cy="42" r="2" fill="currentColor" />
                  <circle cx="82" cy="42" r="2" fill="currentColor" />
                  <circle cx="102" cy="42" r="2" fill="currentColor" />
                  <circle cx="122" cy="42" r="2" fill="currentColor" />
                  <circle cx="142" cy="42" r="2" fill="currentColor" />
                  <circle cx="162" cy="42" r="2" fill="currentColor" />
                  <circle cx="182" cy="42" r="2" fill="currentColor" />
                  <circle cx="2" cy="62" r="2" fill="currentColor" />
                  <circle cx="22" cy="62" r="2" fill="currentColor" />
                  <circle cx="42" cy="62" r="2" fill="currentColor" />
                  <circle cx="62" cy="62" r="2" fill="currentColor" />
                  <circle cx="82" cy="62" r="2" fill="currentColor" />
                  <circle cx="102" cy="62" r="2" fill="currentColor" />
                  <circle cx="122" cy="62" r="2" fill="currentColor" />
                  <circle cx="142" cy="62" r="2" fill="currentColor" />
                  <circle cx="162" cy="62" r="2" fill="currentColor" />
                  <circle cx="182" cy="62" r="2" fill="currentColor" />
                  <circle cx="2" cy="82" r="2" fill="currentColor" />
                  <circle cx="22" cy="82" r="2" fill="currentColor" />
                  <circle cx="42" cy="82" r="2" fill="currentColor" />
                  <circle cx="62" cy="82" r="2" fill="currentColor" />
                  <circle cx="82" cy="82" r="2" fill="currentColor" />
                  <circle cx="102" cy="82" r="2" fill="currentColor" />
                  <circle cx="122" cy="82" r="2" fill="currentColor" />
                  <circle cx="142" cy="82" r="2" fill="currentColor" />
                  <circle cx="162" cy="82" r="2" fill="currentColor" />
                  <circle cx="182" cy="82" r="2" fill="currentColor" />
                  <circle cx="2" cy="102" r="2" fill="currentColor" />
                  <circle cx="22" cy="102" r="2" fill="currentColor" />
                  <circle cx="42" cy="102" r="2" fill="currentColor" />
                  <circle cx="62" cy="102" r="2" fill="currentColor" />
                  <circle cx="82" cy="102" r="2" fill="currentColor" />
                  <circle cx="102" cy="102" r="2" fill="currentColor" />
                  <circle cx="122" cy="102" r="2" fill="currentColor" />
                  <circle cx="142" cy="102" r="2" fill="currentColor" />
                  <circle cx="162" cy="102" r="2" fill="currentColor" />
                  <circle cx="182" cy="102" r="2" fill="currentColor" />
                  <circle cx="2" cy="122" r="2" fill="currentColor" />
                  <circle cx="22" cy="122" r="2" fill="currentColor" />
                  <circle cx="42" cy="122" r="2" fill="currentColor" />
                  <circle cx="62" cy="122" r="2" fill="currentColor" />
                  <circle cx="82" cy="122" r="2" fill="currentColor" />
                  <circle cx="102" cy="122" r="2" fill="currentColor" />
                  <circle cx="122" cy="122" r="2" fill="currentColor" />
                  <circle cx="142" cy="122" r="2" fill="currentColor" />
                  <circle cx="162" cy="122" r="2" fill="currentColor" />
                  <circle cx="182" cy="122" r="2" fill="currentColor" />
                  <circle cx="2" cy="142" r="2" fill="currentColor" />
                  <circle cx="22" cy="142" r="2" fill="currentColor" />
                  <circle cx="42" cy="142" r="2" fill="currentColor" />
                  <circle cx="62" cy="142" r="2" fill="currentColor" />
                  <circle cx="82" cy="142" r="2" fill="currentColor" />
                  <circle cx="102" cy="142" r="2" fill="currentColor" />
                  <circle cx="122" cy="142" r="2" fill="currentColor" />
                  <circle cx="142" cy="142" r="2" fill="currentColor" />
                  <circle cx="162" cy="142" r="2" fill="currentColor" />
                  <circle cx="182" cy="142" r="2" fill="currentColor" />
                  <circle cx="2" cy="162" r="2" fill="currentColor" />
                  <circle cx="22" cy="162" r="2" fill="currentColor" />
                  <circle cx="42" cy="162" r="2" fill="currentColor" />
                  <circle cx="62" cy="162" r="2" fill="currentColor" />
                  <circle cx="82" cy="162" r="2" fill="currentColor" />
                  <circle cx="102" cy="162" r="2" fill="currentColor" />
                  <circle cx="122" cy="162" r="2" fill="currentColor" />
                  <circle cx="142" cy="162" r="2" fill="currentColor" />
                  <circle cx="162" cy="162" r="2" fill="currentColor" />
                  <circle cx="182" cy="162" r="2" fill="currentColor" />
                  <circle cx="2" cy="182" r="2" fill="currentColor" />
                  <circle cx="22" cy="182" r="2" fill="currentColor" />
                  <circle cx="42" cy="182" r="2" fill="currentColor" />
                  <circle cx="62" cy="182" r="2" fill="currentColor" />
                  <circle cx="82" cy="182" r="2" fill="currentColor" />
                  <circle cx="102" cy="182" r="2" fill="currentColor" />
                  <circle cx="122" cy="182" r="2" fill="currentColor" />
                  <circle cx="142" cy="182" r="2" fill="currentColor" />
                  <circle cx="162" cy="182" r="2" fill="currentColor" />
                  <circle cx="182" cy="182" r="2" fill="currentColor" />
                </svg>
              </div>
              <img
                class="position-relative img-fluid rounded-top-start rounded-bottom-end"
                src="/img/home/front.jpg"
                alt="Reno Collective Coworking"
              />
            </div>
            <div className="col-12 offset-n12 text-center"></div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h6 className="text-uppercase mb-6">
                Our members are stoked that you're here
              </h6>
            </div>
          </div>
          <Logos />
        </div>
      </section>
      <section className="pt-10 pt-md-12">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <img
                className="img-fluid mb-4 mb-md-0"
                src="/img/collective/community.png"
                alt="Learn from community"
              />
            </div>
            <div className="col-md-7 col-lg-6">
              <h6 className="text-uppercase text-danger mb-5">
                No more working in a silo
              </h6>
              <h2 className="display-4 mb-4">
                Work for yourself, not by yourself
              </h2>
              <p className="text-muted">
                Our community is full of inspiring and creative people in just
                about every industry you can imagine. Some are just starting
                their journey and others are masters of their craft.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Freelancers</li>
                    <li className="mb-3">Software Developers</li>
                    <li className="mb-3">Designers</li>
                    <li>Students</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Startup Founders</li>
                    <li className="mb-3">Solopreneurs</li>
                    <li className="mb-3">WFHers</li>
                    <li>Remote Employees</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pt-md-12">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 order-md-1">
              <img
                className="img-fluid mb-8 mb-md-0"
                src="/img/collective/office.jpg"
                alt="Reno Collective"
              />
            </div>
            <div className="col-md-7 col-lg-6 order-md-0">
              <h6 className="text-uppercase text-danger mb-5">WFH with Us</h6>
              <h2 className="display-4 mb-4">Coworking in Reno</h2>
              <p className="text-muted">
                We are a community-built, flexible workspace suited for the
                needs of a modern, remote, and hybrid workforce. We offer cafe desks, dedicated desks, and private office
                memberships.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Flexible workspace options</li>
                    <li className="mb-3">
                      Professional and reliable work environment
                    </li>
                    <li className="mb-3">Meeting rooms and phone booths</li>
                    <li>Exclusive Member Perks</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">IRL Cowork Sessions</li>
                    <li className="mb-3">Job & Gig Board</li>
                    <li className="mb-3">Mastermind Sessions</li>
                    <li className="mb-3">Fiber Internet</li>
                    <li className="mb-3">Social Events</li>
                    <li>
                      <a href="/perks">More Perks &rarr;</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-10 pt-md-12 pb-11">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <img
                className="img-fluid mb-4 mb-md-0"
                src="/img/collective/10year.png"
                alt="10 years of RC"
              />
            </div>
            <div className="col-md-7 col-lg-6">
              <h6 className="text-uppercase text-danger mb-5">
                Permission to Thrive
              </h6>
              <h2 className="display-4 mb-4">
                Building up independent workers for a decade
              </h2>
              <p className="text-muted">
                We've been doing this for quite while in Reno and have helped
                thousands for members over the years. We created Reno Collective
                to be the best workspace for everyone.
              </p>
              <p className="text-muted">
                We've taken the best parts of coworking and made them even
                better so we can all do this for another 10+ years.
              </p>
            </div>
          </div>
        </div>
        <div class="container-lg mt-12">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 text-center">
              <h6 class="text-uppercase text-danger mb-5">
                Visit your new office
              </h6>

              <h2 class="display-1 mb-4">Cowork with Us</h2>

              <Link
                to="/community"
                class="btn btn-primary"
                title="See our online memberships"
              >
                Learn more about Reno Collective
              </Link>
              <p class="p-1">
                <small>
                  or ready to join?{' '}
                  <Link
                    to="/join"
                    class="text-primary-light"
                    title="Check out our membership options"
                  >
                    Check out our membership options
                  </Link>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
